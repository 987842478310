/* eslint-disable valid-jsdoc */
import { apiClient } from '../index';

/**
 * [login description]
 * @param  {[type]} economico    [description]
 * @return {[type]}          [description]
 */
function updateEmployee(id, data) {
  return apiClient.patch('/employee/' + id,
      data,
  );
}
/**
 * [login description]
 * @param  {[type]} economico    [description]
 * @return {[type]}          [description]
 */
function createEmployee(data) {
  return apiClient.post('/employee/',
      data,
  );
}

/**
 * [login description]
 * @return {[type]}          [description]
 */
function getEmployees() {
  return apiClient.get('/employee');
}

export default { updateEmployee, createEmployee, getEmployees };
