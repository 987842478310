<template>
  <v-container fluid>
    <header-page-table
      :update-data="getEmployees"
      :input-data="inputData"
      btntext="Crear vendedor/supervisor"
      title="Lista de vendedores y supervisores"
      @save="save($event)"
    />
    <v-alert
      :value="snack"
      :type="snackColor"
    >
      {{ snackText }}
    </v-alert>
    <table-user
      :headers="headers"
      :items="employees"
      :input-data="inputData"
      @edit="edit($event)"
    />
  </v-container>
</template>
<script>
import dateFormatMixin from '@/mixins/dateFormat';

import employeeService from '../services/modules/employee';

import HeaderPageTable from '@/components/HeaderPageTable.vue';

import TableUser from '@/components/TableUser.vue';

export default {
  name: 'Employees',
  components: { HeaderPageTable, TableUser },
  mixins: [dateFormatMixin],
  data() {
    return {
      value: undefined,
      toggle: false,
      snack: false,
      snackColor: 'success',
      snackText: '',
      search: '',
      request: false,
      employees: [],
      inputData: [
        {
          text: 'Nombre',
          id: 'name',
          value: '',
          type: 'text',
          rules: [(v) => !!v || 'El nombre es requerido'],
        },
        {
          cols: 6,
          text: 'Apellido Paterno',
          id: 'lastName1',
          value: '',
          type: 'text',
          rules: [(v) => !!v || 'Campo requerido'],
        },
        {
          cols: 6,
          text: 'Apellido Materno',
          id: 'lastName2',
          value: '',
          type: 'text',
          rules: [],
        },
        {
          cols: 6,
          text: 'GPID',
          id: 'gpid',
          value: '',
          type: 'text',
          rules: [(v) => !!v || 'El GPID es requerido'],
        },
        {
          cols: 6,
          text: 'CEDIS',
          id: 'cedis',
          value: '',
          type: 'text',
          rules: [(v) => !!v || 'El CEDIS es requerido'],
        },
        {
          cols: 6,
          text: 'Email',
          id: 'email',
          value: '',
          type: 'text',
          rules: [
            (v) => !!v || 'El email es requerido',
            (v) => /.+@.+\..+/.test(v) || 'E-mail debe ser valido',
          ],
        },
        {
          cols: 3,
          text: 'Vendedor',
          id: 'vendedor',
          value: '',
          type: 'select',
          items: ['SI', 'NO'],
          rules: [(v) => !!v || 'Campo requerido'],
        },
        {
          cols: 3,
          text: 'Soporte',
          id: 'flowBot',
          value: '',
          type: 'select',
          items: ['ecobotin', 'inase', 'ambos'],
          rules: [(v) => !!v || 'Campo requerido'],
        },
        {
          text: 'Telefono del usuario',
          id: 'cellphone',
          value: '',
          type: 'cellphone',
          rules: [(v) => (!!v && v.length === 10) || 'Lada + Numero (10 digitos)'],
        },
        {
          cols: 7,
          text: 'Nombre del supervisor',
          id: 'supervisorName',
          value: '',
          type: 'text',
          hidden: {
            si: [],
            no: [(v) => !!v || 'El nombre del supervisor es requerido'],
          },

        },
        {
          cols: 5,
          text: 'Telefono del supervisor',
          id: 'supervisorPhone',
          value: '',
          type: 'cellphone',
          hidden: {
            si: [],
            no: [(v) => (!!v && v.length === 10) || 'Lada + Numero (10 digitos)'],
          },
        },
        {
          cols: 7,
          text: 'Nombre del facilitador',
          id: 'facilitatorName',
          value: '',
          type: 'text',
          hidden: {
            si: [],
            no: [(v) => !!v || 'El campo es requerido'],
          },
        },
        {
          cols: 5,
          text: 'Telefono del facilitador',
          id: 'facilitatorPhone',
          value: '',
          type: 'cellphone',
          hidden: {
            si: [],
            no: [(v) => (!!v && v.length === 10) || 'Lada + Numero (10 digitos)'],
          },
        },
        {
          cols: 7,
          text: 'Nombre del inase',
          id: 'inaseName',
          value: '',
          type: 'text',
          hidden: {
            si: [],
            no: [(v) => !!v || 'El campo es requerido'],
          },
        },
        {
          cols: 5,
          text: 'Telefono del inase',
          id: 'inasePhone',
          value: '',
          type: 'cellphone',
          hidden: {
            si: [],
            no: [(v) => (!!v && v.length === 10) || 'Lada + Numero (10 digitos)'],
          },
        },
        {
          cols: 7,
          text: 'Nombre del inaseMaster',
          id: 'inaseMasterName',
          value: '',
          type: 'text',
          hidden: {
            si: [],
            no: [(v) => !!v || 'El campo es requerido'],
          },
        },
        {
          cols: 5,
          text: 'Telefono del inaseMaster',
          id: 'inaseMasterPhone',
          value: '',
          type: 'cellphone',
          hidden: {
            si: [],
            no: [(v) => (!!v && v.length === 10) || 'Lada + Numero (10 digitos)'],
          },
        },
      ],
      headers: [
        // { text: 'id', value: 'id' },
        { text: '', value: 'more', sortable: false },
        { text: 'GPID', value: 'gpid' },
        { text: 'Vendedor', value: 'vendedor' },
        { text: 'Nombre', value: 'name' },
        { text: 'CEDIS', value: 'cedis' },
        { text: 'Telefono', value: 'cellphone' },
        { text: 'email', value: 'email' },
        // { text: 'status', value: 'status' },
        { text: 'Creado', value: 'createdAt' },
        { text: 'Nombre del facilitador', value: 'facilitatorName' },
        { text: 'Número del facilitador', value: 'facilitatorPhone' },
        { text: 'Nombre del supervisor', value: 'supervisorName' },
        { text: 'Número del supervisor', value: 'supervisorPhone' },
        { text: 'Nombre del inase', value: 'inaseName' },
        { text: 'Número del inase', value: 'inasePhone' },
        { text: 'Nombre del inase master', value: 'inaseMasterName' },
        { text: 'Número del inase master', value: 'inaseMasterPhone' },
        // { text: 'updatedAt', value: 'updatedAt' },
      ],
    };
  },
  mounted() {
    this.getEmployees();
  },
  methods: {
    update(msg) {
      console.log(msg);
    },
    getEmployees() {
      this.request = true;
      employeeService
          .getEmployees()
          .then((response) => {
            this.request = false;
            this.employees = response.data;
          })
          .catch((error) => {
            this.request = false;
            console.error('FAILURE!!', error);
          });
    },

    async save(data) {
      try {
        await employeeService.createEmployee(data);
        this.getEmployees();
        this.snack = true;
        this.snackColor = 'success';
        this.snackText = 'Data saved';
        this.getEmployees();
      } catch (error) {
        this.snack = true;
        this.snackColor = 'error';
        this.snackText = 'Problem with data';
      }
      setTimeout(() => {
        this.snack = false;
      }, 3000);
    },

    async edit(data) {
      try {
        await employeeService.updateEmployee(data.id, data);
        this.snack = true;
        this.snackColor = 'success';
        this.snackText = 'Data saved';
        this.getEmployees();
      } catch (error) {
        this.snack = true;
        this.snackColor = 'error';
        this.snackText = 'Problem with data';
      }
      setTimeout(() => {
        this.snack = false;
      }, 3000);
    },
  },
};
</script>

